.Table {
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    font-weight: unset;
    padding-right: 10px;
    border: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  tr {
    a {
      display: block;
    }

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.Column-first {
  padding-left: 40px;
}

.Column-5 {
  width: 5%;
  overflow: hidden;
}

.Column-10 {
  width: 10%;
  overflow: hidden;
}

.Column-15 {
  width: 15%;
  overflow: hidden;
}

.Column-20 {
  width: 20%;
  overflow: hidden;
}

.Column-25 {
  width: 25%;
  overflow: hidden;
}

.Column-30 {
  width: 30%;
  overflow: hidden;
}

.TableHead th {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
}

.TableBody td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.TableContainer {
  position: relative;
  padding-top: 54px;
  box-shadow: 3px 3px 6px #00000029;
}

.TableHead {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(108, 104, 172);
  background: linear-gradient(144deg, rgba(108, 104, 172, 1) 0%, rgba(102, 128, 177, 1) 100%);
}

.TableBody {
  overflow: auto;
}

.TableContainer th {
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
}

.TableContainer td {
  font-size: 15px;
  line-height: 1.4;
}

.TableContainer .TableBody tr:nth-child(even) {
  background-color: #eeeeee;
}

/*---------------------------------------------*/

.TableContainer {
  border-radius: 10px;
  overflow: hidden;
}

.TableContainer .ps__rail-y {
  right: 5px;
}

.TableContainer .ps__rail-y::before {
  background-color: #ebebeb;
}

.TableContainer .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}
