.ButtonsContainer {
  position: relative;
}

.Buttons {
  position: absolute;
  top: 15px;
  right: 15px;

  button {
    border: none;
    background: none;
    padding: 5px;
    color: var(--primary-color);
    font-size: 18px;
    cursor: pointer;

    + button {
      margin-left: 10px;
    }
  }
}
