.RadioGroup {
  color: var(--text-grey-color);
  display: flex;
}

.Radio {
  display: flex;
  padding-left: 20px;
  align-items: center;

  &:first-child {
    padding: 0;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    &:checked + .RadioButtonChecked {
      display: block;
    }
  }
}

.RadioButtonContainer {
  margin-right: 8px;
  position: relative;
  width: 20px;
  height: 20px;
}

.RadioButton {
  margin-right: 8px;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
}

.RadioButtonChecked {
  display: none;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background: var(--color-primary);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

input:focus + .RadioButtonChecked + .RadioButton {
  box-shadow: 0 0 0 2px var(--color-primary-shadow);
}
