.Tab {
  padding: 10px 15px 10px 20px;
  outline: none;
  background: transparent;
  border: solid 1px var(--border-input);
  color: currentColor;
  min-width: 130px;

  &:focus {
    box-shadow: 0 0 0 2px var(--color-primary-shadow);
  }

  & + .Tab {
    border-left: none;
  }
}

.TabSelected {
  background: #EDE8E8;
}

.TabList {
  color: var(--text-grey-color);

  .Tab:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .Tab:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}

.TabPanel {
  outline: none;
  padding-top: 30px;
}
