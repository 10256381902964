.Menu {
  background: rgb(108,104,172);
  background: linear-gradient(144deg, rgba(108,104,172,1) 0%, rgba(102,128,177,1) 100%);
  text-align: center;
  padding: 50px 0;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 50;
  display: flex;
  flex-direction: column;
}

.Logo {
  width: 140px;
  margin: 0 auto;
}

.Spacer {
  margin: 40px 0px;
  border-color: #FFFFFF;
}

.MenuItem {
  padding: 0 20px;
  align-items: center;
  text-align: left;
  font-size: 1.2rem;
  height: 60px;
  width: 100%;
  background: transparent;
  display: flex;
  text-decoration: none !important;
  color: white;
  border-left: solid 4px transparent;
  font-weight: bold;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.MenuItemBottom {
  margin-top: auto;
}

.MenuItemActive {
  background: linear-gradient(90deg, rgba(255,255,255,0.2847514005602241) 0%, rgba(251,252,252,0.15870098039215685) 52%, rgba(251,252,252,0) 100%);
  color: white;
  border-left-color: white;
}

.MenuItemIcon {
  margin-right: 25px;
  height: 1.2rem;
  width: 1.2rem;

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.MenuItemText {
  line-height: 20px;
}

.FullscreenBackdrop {
  display: none;
  transition: opacity 200ms ease-in;
}

@media (max-width: 960px) {
  .FullscreenBackdrop {
    position: fixed;
    z-index: 30;
    background: black;
    opacity: 0.3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
