.PrincipalLayout {
  display: flex;
  width: 100%;
}

.PrincipalLayoutBox {
  width: 100%;
  height: 100%;
}

.PrincipalLayoutBoxFullWidth {
  .ZoneContent {
    max-width: 100%;
  }
}

.MenuContainer {
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform 200ms ease-out;
}

.ContentContainer {
  padding-left: 250px;
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  transition: padding-top 200ms ease-out;
}

.Title {
  margin-left: 70px;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.SubTitle {
  margin-left: 70px;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0 20px 10px 20px;
  margin-top: 24px;
  display: inline-block;
  border-bottom: solid 4px rgb(108, 104, 172);
}

.LinkTab .SubTitle {
  border-color: transparent;
}

.LinkTab + .LinkTab .SubTitle {
  margin-left: 20px;
}

.LinkTabActive .SubTitle {
  border-bottom: solid 4px rgb(108, 104, 172);
}

.Title {
  margin-bottom: 10px;
}

.Content {
  background: #f2f4f7;
  padding-left: 70px;
  padding-right: 50px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
}

.Zone {
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  padding: 25px;
}

.ZoneHollow {
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.Zone + .Zone {
  margin-top: 30px;
}

.ZoneTitle {
  font-size: 1.5rem;
}

.ZoneDescription {
  line-height: 1.5rem;
  white-space: pre-line;
  color: var(--color-light-grey);
  margin: 0;
  padding-top: 15px;
}

.ZoneContent {
  padding-top: 25px;
  max-width: 900px;
}

.ZoneContentTitle {
  font-size: 1.3rem;
  padding-bottom: 25px;
}

.ZoneInnerContent {
  display: flex;
}

.ZoneContentTitle + .ZoneInnerContent {
}

.ZoneDescription + .ZoneContent {
}

.ZoneColumn {
  flex: 1;
}

.ZoneColumn + .ZoneColumn {
  margin-left: 50px;
}

.ZoneLoadingState {
  text-align: center;
  color: var(--color-primary);

  svg {
    height: 50px;
    width: 50px;
  }
}

.WarningZone {
  background: #97faa6;
  border-radius: 8px;
  color: #333;
  padding: 15px;
  margin-bottom: 40px;
  border-left: solid 5px #09e809;
  max-width: 600px;

  .WarningZoneTitle {
    font-weight: 900;
    margin: 0;
    padding: 0;
  }

  .WarningZoneContent {
    margin: 10px 0 0 0;
    padding: 0;
  }
}

.SignoutButton {
  border: none;
  background: none;
  color: currentColor;
  cursor: pointer;
  width: 80px;
  height: 80px;
  padding: 0;
  margin: 0;
  outline: none;

  svg {
    height: 32px;
    width: 32px;
  }
}

.SignoutContainer {
  position: absolute;
  top: 30px;
  right: 50px;
}

.OpenMenuContainer {
  transform: translateY(-200px);
  opacity: 0;
  transition: all 200ms ease-in-out;
  position: absolute;
  top: 10px;
  left: 60px;
}

.OpenMenuButton {
  outline: none;
  border: none;
  background: none;
  color: currentColor;
  cursor: pointer;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;

  svg {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 960px) {
  .OpenMenuContainer {
    transform: translateY(0);
    opacity: 1;
  }

  .ZoneInnerContent {
    display: block;
  }

  .ZoneColumn + .ZoneColumn {
    margin: 0;
  }

  .MenuContainer {
    transform: translateX(-100%);
    z-index: 100;
  }

  .MenuContainerOpen {
    transform: translateX(0);
  }

  .ContentContainer {
    padding-left: 0;
    padding-top: 80px;
  }

  .Content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .SignoutContainer {
    top: 0;
    right: 60px;
  }
}
