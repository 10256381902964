.Field {
  padding-bottom: 15px;
}

.FieldLeft {
  text-align: left;
}

.FieldRight {
  text-align: right;
}

.FieldCenter {
  text-align: center;
}

.FieldError {
  font-size: 0.8rem;
  margin-left: 20px;
  margin-top: 10px;
}
