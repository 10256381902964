.Help {
  button {
    font-weight: bold;
  }
}

.ZoneContentContainer {
  width: 100%;
  text-align: center;

  p {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  p + p {
    margin: 0 0 10px 0;
  }
}

@media (max-width: 960px) {
  .ZoneContentContainer {
    margin-bottom: 50px;
  }
}
