.Day {
  display: inline-block;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.DayNumber {
  padding-bottom: 5px;
  font-weight: bold;
}

.DayLetter {
  font-weight: bold;
  padding-bottom: 5px;
}

.DayCheckbox {
}

.Days {
  display: flex;
}

.Weeks {
  display: flex;
}

.Week {
  padding: 5px;
  text-align: center;
  border: solid 2px var(--color-primary);
  border-right: none;
  font-weight: bold;

  &:last-child {
    border-right: solid 2px var(--color-primary);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.WeekTitle {
  padding-bottom: 10px;
}

.Weekend {
  .DayNumber,
  .DayLetter {
    color: var(--color-blue);
  }
}

.Ferie {
  .DayNumber,
  .DayLetter {
    color: var(--color-orange);
  }
}

.Legend {
}
