.PasswordStrengthValidator {
  display: flex;
  font-size: 0.8rem;
  color: #707070;
  margin-left: 20px;
}

.Dot {
  margin-left: 12px;
  display: flex;
  align-items: center;

  &:first-child {
    margin: 0;
  }
}

.DotInvalid {
  .DotIcon {
    background: #b22222;
  }
}

.DotIcon {
  margin-right: 5px;
  width: 6px;
  height: 6px;
  background: #E3E3E3;
  border-radius: 50%;
}
