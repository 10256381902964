.AuthLayout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  color: #707070;
}

.Left {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100vh;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;

  > img {
    width: 140px;
    margin-bottom: 30px;
  }
}

.Right {
  max-height: 100vh;
  overflow-x: hidden;
  min-height: 100vh;
  height: 100vh;
  background-image: linear-gradient(90deg, transparent, #bcb9eb);
  max-width: 40vw;

  img {
    height: 100%;
  }
}

@media (max-width: 960px) {
  .Left {
    padding-bottom: 0;
  }

  .Right {
    display: none;
  }

  .ChildrenContainer {
    width: 100%;
    height: 100%;
  }
}
