.UsersListingModal {
  max-height: 400px;
  overflow-y: auto;
}

.Fields {
  display: block;
  flex-direction: row;
}

.ModalStartingButton {
  text-align: right;
}
