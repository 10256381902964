.Select {
  position: relative;
}

.SelectInput {
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  outline: none;
  color: #707070;
  font-size: 16px;
  width: 100%;
  display: grid;
  height: 40px;
  position: relative;
  -webkit-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    box-shadow: 0 0 0 2px #6436d881;
  }

  &::placeholder {
    color: #707070;
    font-style: italic;
  }

  &::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

.SelectIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  pointer-events: none;
}

.SelectLabel {
  pointer-events: none;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  top: 10px;
  color: var(--text-input);
  background: white;
  top: -0.3rem;
  left: 15px;
  font-size: 0.6rem;
  z-index: 10;
}

.Disabled {
  cursor: not-allowed;
  background: #f0f0f0;
  color: #9e9e9e;
}

.Errored {
  border-color: #b22222;
  color: #b22222;
}
