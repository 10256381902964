.CheckboxGroup {
  color: var(--text-grey-color);
  display: flex;
}

.Checkbox {
  display: flex;
  padding-left: 20px;
  align-items: center;
  height: 40px;

  &:first-child {
    padding: 0;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    &:checked + .CheckboxButtonChecked {
      display: block;
    }

    &:indeterminate + .CheckboxButtonChecked + .CheckboxButtonIndeterminate {
      display: block;
    }

    &:focus + .CheckboxButtonChecked + .CheckboxButtonIndeterminate + .CheckboxButton {
      box-shadow: 0 0 0 2px var(--color-primary-shadow);
    }

    &:disabled + {
      .CheckboxButtonChecked + {
        color: #333;
        .CheckboxButtonIndeterminate + {
          color: #333;
          .CheckboxButton {
            border-color: #333;
          }
        }
      }
    }
  }

  &.Small {
    height: auto;

    .CheckboxButtonContainer {
      margin-right: 0;
      width: 15px;
      height: 15px;
      font-size: 10px;
    }

    .CheckboxButtonIndeterminate {
      transform: translateX(-50%) translateY(-53%);
      font-size: 18px;
      line-height: 40px;
    }
  }
}

.NoLabel {
  .CheckboxButtonContainer {
    margin-right: 0;
  }

  .CheckboxButton {
    margin-right: 0;
  }
}

.CheckboxButtonContainer {
  margin-right: 8px;
  position: relative;
  width: 20px;
  height: 20px;
  flex: none;
}

.CheckboxButton {
  margin-right: 8px;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2px solid var(--color-primary);
}

.CheckboxButtonChecked {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.CheckboxButtonIndeterminate {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-55%);
  font-size: 30px;
  line-height: 40px;
}

input:focus + .CheckboxButtonChecked + .CheckboxButton {
  box-shadow: 0 0 0 2px var(--color-primary-shadow);
}
