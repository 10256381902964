.AuthPanel {
  width: 575px;
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 15px;
  background: #F7F7F7;
  padding: 40px 30px 30px 30px;

  color: var(--text-grey-color);

  h1 {
    font-size: 1.1rem;
    padding-left: 30px;
  }

  h2 {
    padding-left: 30px;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 10px;

    a {
      font-weight: bold;
    }
  }
}

.Content {
  padding-top: 30px;
}

@media (max-width: 960px) {
  .AuthPanel {
    width: 100%;
    border-radius: 0;
    height: 100%;
  }
}
