.TextareaContainer {
  position: relative;
  background: white;
  border-radius: 4px;
}

.TextareaLabel {
  pointer-events: none;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  top: 10px;
  color: var(--text-input);
  background: white;
  display: none;
  top: -0.3rem;
  left: 15px;
  font-size: 0.6rem;
}

.TextareaFilled {
  .TextareaLabel {
    display: block;
  }
}

.Textarea {
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid var(--border-input);
  outline: none;
  color: var(--color-grey);
  font-size: 16px;
  width: 100%;
  background: transparent;
  resize: none;

  &:focus {
    box-shadow: 0 0 0 2px var(--color-primary-shadow);
  }

  &::placeholder {
    color: var(--placeholder-input);
  }
}

.Disabled {
  cursor: not-allowed;
  color: #9e9e9e;
  -webkit-text-fill-color: #9e9e9e;
  opacity: 1;
}

.Errored {
  border-color: var(--text-error);
  color: var(--text-error);
}
