.Slider {
  height: 40px;
  padding: 10px 0;
  position: relative;

  :global(.rc-slider-handle), :global(.rc-slider-handle:hover) {
    border-color: var(--primary-color) !important;
  }

  :global(.rc-slider-handle:active) {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
  }

  :global(.rc-slider-handle-click-focused) {
    border-color: var(--primary-color);
  }

  :global(.rc-slider-dot-active) {
    border-color: var(--primary-color);
  }

  :global(.rc-slider-track) {
    background-color: var(--primary-color);
  }
}

.SliderLabel {
  pointer-events: none;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--text-input);
  background: white;
  top: -0.3rem;
  left: -8px;
  font-size: 0.6rem;
}

@media (max-width: 960px) {
  .Slider {
    padding: 10px 10px;
  }

  .SliderLabel {
    left: 15px;
  }
}
