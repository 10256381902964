.AppLoader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppLoaderContainer {
  height: 100px;
  width: 100px;
  margin-top: 200px;

  svg {
    color: var(--color-primary);
    height: 100px;
    width: 100px;
  }
}

@keyframes NotificationEntrance {
  from {
    transform: translateX(150%);
  }

  to {
    transform: translateX(0);
  }
}

.Notification {
  position: fixed;
  top: 90px;
  right: 40px;
  animation: NotificationEntrance 400ms ease-in-out;
  border-radius: 8px;
  color: #333;
  padding: 15px 20px;
  background: #97faa6;
  border-left: solid 5px #09e809;
  z-index: 50;
}

.NotificationError {
  background: #FA9F97;
  border-left: solid 5px #e81f09;
}

a {
  color: var(--color-primary);
}
