.Button {
  padding: 10px 25px;
  border-radius: 4px;
  border: 2px solid #6c68ac;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease-in;

  &:focus {
    box-shadow: 0 0 0 2px var(--color-primary-shadow);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.Big {
  font-size: 1.3rem;
}

.Primary {
  background: #6c68ac;
  background: linear-gradient(144deg, #6c68ac 0%, #6680b1 100%);
  color: white;
  transition: all 100ms ease-in;

  &:hover {
    background: transparent;
    color: var(--color-primary);
  }
}

.Secondary {
  background: transparent;
  color: var(--color-primary);
}

.IsLoading {
  padding-right: 45px;
  position: relative;

  .LoadingIcon {
    display: block;
  }
}

.LoadingIcon {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 25px;
  width: 25px;
}

.IconButton {
  cursor: pointer;
  border: none;
  background: none;
}
