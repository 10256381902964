.Action {
}
.SpinnerContainer {
  color: var(--color-primary);
  margin-top: 50px;

  svg {
    height: 50px;
    width: 50px;
  }
}

.FormContainer {
  max-width: 450px;
  margin: 0 auto;
}

.text {
  margin-top: 80px;
  margin-bottom: 40px;
}
