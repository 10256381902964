.Modal {
}

.ModalBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #00000050;
}

.ModalContent {
  background: white;
  width: 500px;
  position: fixed;
  top: calc(50% - 50px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 30px;
  border-radius: 5px;
}

.ModalTitle {
  color: var(--color-grey);
  font-size: 20px;
  margin-bottom: 10px;
}

.ModalDescription {
  font-size: 15px;
  margin-bottom: 25px;
  color: var(--color-grey);
}

@media (max-width: 960px) {
  .ModalContent {
    width: 100%;
  }
}

.ModalFull {
  width: 90%;
  height: 90%;
  overflow-y: auto;
}
