.Link {
  color: var(--color-primary);
}

.LinkAsButton {
  padding: 10px 25px;
  border-radius: 4px;
  border: 2px solid #6c68ac;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease-in;

  &:focus {
    box-shadow: 0 0 0 2px var(--color-primary-shadow);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  text-decoration: none;
  background: #6c68ac;
  background: linear-gradient(
144deg
, #6c68ac 0%, #6680b1 100%);
  color: white;
  transition: all 100ms ease-in;

  &:hover {
    background: transparent;
    color: var(--color-primary);
  }
}
