.AdminProfilePrestatairePreview {
}

.CvInfosZone {
  position: relative;
}

.DownloadLink {
  display: flex;
  position: absolute;
  top: 30px;
  right: 30px;

  a {
    margin-left: 10px;
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      width: 30px;
    }

    span {
      margin-right: 10px;
    }
  }
}

.TitleWithIcon {
  svg {
    width: 16px;
    height: 16px;
  }
}

.ProfileInfosZone {
  position: relative;
}

.ButtonsZone,
.ModalContainer {
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;

  :nth-child(1) {
    margin-right: 10px;
  }
}
