.PrestataireList {
  width: 100%;
  padding: 15px 30px;
}

.Container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.PrestataireListItem {
  cursor: pointer;
  background: white;
  min-height: 270px;
  max-height: 270px;
  border: solid 2px var(--primary-color);
  border-radius: 15px;
  width: 350px;
  padding: 30px;
  margin-top: 20px;
  margin-right: 20px;
  display: inline-block;
  position: relative;
  outline: none;
}

.PrestataireListItemTall {
  min-height: 300px;
  max-height: 300px;
}

.Availability {
  top: 20px;
  position: absolute;
}

@media (max-width: 960px) {
  .PrestataireListItem {
    margin-right: 0;
  }
}

.Title {
  font-size: 25px;
  font-weight: bold;
  color: black;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Tjm {
  top: 15px;
  right: 15px;
  position: absolute;
  padding: 5px 10px;
  color: white;
  font-size: 16px;
  background: grey;
  border-radius: 10px;
  display: inline-block;
}
.Skills {
  margin-top: 20px;
  max-height: 85px;
  overflow: hidden;
}
.Skill {
  color: white;
  font-size: 12px;
  margin-right: 5px;
  margin-top: 5px;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
}
.InternalSkill {
  display: inline-block;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 5px 5px 10px;
  background: var(--primary-color);
}

.InternalSkill + .InternalSkill {
  padding: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 2px solid white;
}

.ChooseProfile {
  position: absolute;
  right: 15px;
  top: 50px;
}
.Availability {
}
.City {
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  svg {
    width: 25px;
    height: 25px;
  }
}

.ModalBackdrop {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #00000050;
}

.ModalContent {
  background: white;
  width: 800px;
  position: fixed;
  top: calc(50% - 50px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 30px;
  border-radius: 5px;
  height: 80%;
  overflow-y: auto;
  outline: none;
}

.ProfileDetails {
  .City {
    top: 0;
    height: 60px;
  }

  .Availability {
    margin-top: 20px;
    font-size: 18px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .Missions {
    margin-top: 20px;

    .MissionsTitle {
      display: block;
      font-size: 20px;
      margin-bottom: 15px;
    }

    .DataMissionContainer {
      padding: 10px;
      border: solid 2px var(--primary-color);
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }

  .Spacer {
    margin-top: 15px;
  }
}

.DetailsSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  svg {
    height: 100px;
    width: 100px;
  }
}
