.ForgotPanel {
}

.ForgotPanelContent {
  padding: 0 30px;
  text-align: center;
}

.Error {
  color: #e81f09;
}
