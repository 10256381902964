@import-normalize;

:root {
  --color-primary: #5C2CD6;
  --color-primary-shadow: #6436d881;
  --color-grey: #707070;
  --color-light-grey: #828282;
  --color-almost-white: #dfdfdf;
  --color-orange: #e4840d;
  --color-yellow: #e4dd0d;
  --color-green: #2ebd63;
  --color-blue: #3e8adc;
  --color-red: #b22222;
  --color-dark-grey: #717171;

  --color-text: #575757;
  --color-title: #2e2e2e;

  --text-error: var(--color-red);
  --text-grey: var(--grey-color);
  --border-input: #b6b6b6;
  --text-input: var(--color-dark-grey);
  --placeholder-input: var(--color-grey);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  color: var(--color-text);
}

html, body, #root {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary-color: #5C2CD6;
  --primary-color-shadow: #6436d881;
  --text-grey-color: #707070;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-size: 1rem;
}

input {
  font-size: 1rem;
}

a {
  font-size: 1rem;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color: var(--color-title);
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  color: var(--color-title);
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  color: var(--color-title);
}

h4 {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  color: var(--color-title);
}
