.Pagination {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}

.Page {
  padding: 10px 15px;
  background: white;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: black;

  &.Current {
    background: var(--primary-color);
    color: white;
  }
}

.Total {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
