.Form {
  width: 100%;
}

.Row {
  margin-bottom: 3px;

  .RowInner {
    display: flex;
  }
}

.RowInnerElementContainer {
  padding-right: 50px;
}

.RowInnerElementContainer:last-child {
  padding-right: 0;
}

@media (max-width: 960px) {
  .Row {
    .RowInner {
      display: block;

      .RowInnerElementContainer {
        padding-right: 0;
      }
    }
  }
}
