.Presentation {
}

.DataContainer {
  color: var(--color-dark-grey);
  font-weight: bold;
}

.DataLabelContainer {
  color: var(--color-light-grey);
  margin-bottom: 5px;
  text-transform: uppercase;
}

.DataRowContainer {
  align-items: center;
  margin-bottom: 15px;
}

.Label {
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  margin: 3px;
}

.LabelRevert {
  color: white;
}

.OuiOuNon {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.DataDateContainer {
  color: var(--color-light-grey);
  font-size: 15px;
  text-align: right;
}

.DataMissionContainer {
  border-bottom: solid var(--color-light-grey) 2px;

  & + .DataMissionContainer {
    margin-top: 20px;
  }
}
