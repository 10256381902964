.TextAuthLayout {
  height: 100%;
  width: 100%;

  margin-top: 70px;
  text-align: center;
  padding-bottom: 20px;

  p + p {
    margin-top: 80px;
    margin-bottom: 40px;
  }
}

.t2 {
  margin-top: 80px;
  margin-bottom: 40px;
}

