.AdminProfilePrestatairePreview {
}

.CvInfosZone {
  position: relative;
}

.DownloadLink {
  position: absolute;
  top: 30px;
  right: 30px;

  a {
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      width: 30px;
    }

    span {
      margin-right: 10px;
    }
  }
}

.ProfileInfosZone {
  position: relative;
}

.ModalContainer {
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;

  :nth-child(1) {
    margin-right: 10px;
  }
}

.ProfilesTitle {
  margin: 0;
  padding: 30px 0 0 30px;
}
